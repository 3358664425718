/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'); */

@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/static/Inter-Regular.ttf') format('truetype'),
    url('fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/static/Inter-Bold.ttf') format('truetype'),
    url('fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  width: 100%; /* Default width */
  height: 250px;
}

@media (min-width: 768px) {
  .responsive-image {
    width: 400px; /* Width for medium-sized screens */
    height: 250px;
  }
}
